<template>
	<div>
		<p>保险开始日期：{{ InsurSearchInfo.StartDate }}</p>
		<p>保险结束日期：{{ InsurSearchInfo.EndDate }}</p>

		<div>
			<h5>保险产品列表</h5>
			<p v-if="InsuranceRates.length == 0">正在加载中..</p>

			<div v-for="(item, index) in InsuranceRates" :key="index">
				<p>产品标题：{{ item.ProviderName }}{{ item.ProductName }}</p>
			</div>
		</div>

		<p>{{ day }}</p>

		<div id="mapContainer">
			<iframe
				id="itomFr"
				name="leftframe"
				src="http://fhlocal.yiqifei.com/MapAPI/Index"
				marginwidth="10"
				marginheight="10"
				frameborder="no"
				width="100%"
				scrolling="yes"
				height="500px "
			></iframe>
		</div>

		<!-- <calendar
			v-model="value"
			show-date-only
			:disabled-days-of-week="disabled"
			:format="format"
			:clear-button="clear"
			:placeholder="placeholder"
			:lang="lang"
			:position="position"
			:on-day-click="dayClick"
			:pane="2"
		> -->
	</div>
</template>

<script>
// import Calendar from 'vue2-slot-calendar';

var util = require('@/utils/util.js'); //通用类库
var datehelper = require('@/utils/datehelper.js'); //通用类库
var yqfCommon = require('@/utils/yqfCommon.js'); //通用类库
var insApi = require('@/api/insApi.js'); //酒店接口
var that;
var qqmapsdk;
var mayKey = 'K2PBZ-2HEW3-ZPD35-YMKJE-OUZKJ-LQBUT';

export default {
	/*组件*/
	components: {
		// calendar: Calendar
	},
	data() {
		return {
			InsurSearchInfo: {}, //保险查询的信息
			InsuranceRates: [], //保险列表
			day: 0,
			arr2: ['2019/4/2', '2019/4/3', '2019/4/4'],
			disabled: [0,1,2,3,4,5,6], //禁用的日期
			value: new Date(), //值
			value1: new Date(), //值
			value2: new Date(), //值
			format: 'yyyy-MM-dd', //文本框中显示的日期格式
			clear: false, //是否在文本框右侧显示清除按钮（X）
			placeholder: '日期', //水印
			position: 'left', //日期面板位置，默认null和left 居左，right则靠右
			lang: 'zh-CN' //语言，默认中文，可选值：zh-CN、en
		};
	},
	created() {
		that = this;
		var query = that.$route.query;
		console.log(query);
		getInsurSearchInfoJSON();

		// that.day = getDaysBetween('2020-11-11', '2020-11-15');
		//实例化API核心类
		// qqmapsdk = new QQMapWX({
		//     key: mayKey
		// });
		// initMap();

		var idCard = '445236498765';
		// console.log(yqfCommon.getIDCardInfoBy(idCard));
		// console.log(yqfCommon.getBirthdayFromIdCard(idCard));
		// console.log(yqfCommon.getAgeFromIdCard(idCard));
		// console.log(yqfCommon.changeToChinese(160));

		window.VueCalendarLang = function(lang) {
			return {
				daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
				limit: '超过限制 (最多{{limit}}项)',
				loading: '加载中...',
				minLength: '最小长度',
				months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一', '十二'],
				notSelected: '未选择',
				required: '必填项',
				search: '查找'
			}; //对象必须是下面JSON格式
		};
	},
	methods: {
		onDrawDate: function(e) {
			console.log(e);
			return false;
		},
		dayClick: function(date, dateStr) {
			console.log(date);
			console.log(dateStr);
			console.log(this.value);
		}
	}
};
function loadScript() {
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = 'https://map.qq.com/api/gljs?v=1.exp&key=' + mayKey + '&callback=init';
	document.body.appendChild(script);

	initMap();
}
function getDaysBetween(dateString1, dateString2) {
	var startDate = Date.parse(dateString1);
	var endDate = Date.parse(dateString2);
	var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
	// alert(days);
	return days;
}

function initMap() {
	// maps.init(mayKey, () => {
	// 	let latLon = new qq.maps.LatLng(39.98412, 116.307484);
	// 	let myOpts = {
	// 		zoom: 14,
	// 		center: latLon,
	// 		mapTypeId: qq.maps.MapTypeId.ROADMAP
	// 	};
	// 	that.map = new qq.maps.Map(document.getElementById('mapContainer'), myOpts);
	// });

	// //定义地图中心点坐标
	// var center = new TMap.LatLng(39.98412, 116.307484);
	// //定义map变量，调用 TMap.Map() 构造函数创建地图
	// var map = new TMap.Map(document.getElementById('container'), {
	// 	center: center, //设置地图中心点坐标
	// 	zoom: 17.2, //设置地图缩放级别
	// 	pitch: 43.5, //设置俯仰角
	// 	rotation: 45 //设置地图旋转角度
	// });

	//定义地图中心点坐标
	var center = new TMap.LatLng(39.98412, 116.307484);
	//定义map变量，调用 TMap.Map() 构造函数创建地图
	var map = new TMap.Map(document.getElementById('mapContainer'), {
		center: center, //设置地图中心点坐标
		zoom: 17.2 //设置地图缩放级别
	});
}

//获取查询的信息
function getInsurSearchInfoJSON() {
	insApi.GetInsurSearch(null, function(result) {
		console.log(result);
		if (result.code == 0) {
			that.InsurSearchInfo = result.data.InsurSearchInfo;
			//获得查询信息之后将查询的信息传入保险列表的接口去查询列表信息
			getInsuranceRateList();
		}
	});
}
//获取保险列表
function getInsuranceRateList() {
	console.log(that.InsurSearchInfo);
	var parm = {
		InsurSearchInfoJSON: JSON.stringify(that.InsurSearchInfo)
	};
	insApi.GetInsuranceRateList(parm, function(result) {
		console.log(result);
		if (result.code == 0) {
			that.InsuranceRates = result.data.InsuranceRates;
		}
	});
}
</script>

<style></style>
